$navbar-height: 3.25rem;
@import "~bulma";
$fa-font-path: "/node_modules/font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";

$tcf-blue: #159dc6;
$tcf-red: #d02424;

$footer-height: 450px;

html,body {
    background: #EFF3F4;
    //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    //font-family: 'Muli', sans-serif;
    font-family: 'Roboto', sans-serif;
}

html.has-navbar-fixed-top, body.has-navbar-fixed-top {
    // This rule comes from Bulma but looks terrible on iOS, apply to hero instead
    padding-top: 0;

    #site {
        padding-top: $navbar-height;
    }
}

iframe {
    max-width: 100%;
}

.hero-body .container {
  max-width: 700px;
}
.hero-body .title {
  color: hsl(192,17%,99%) !important;
}
.hero-body .subtitle {
  color: hsl(192,17%,99%) !important;
  padding-top: 2rem;
  line-height: 1.5;
}
.features {
  padding: 5rem 0;
}
.box.cta {
  border-radius: 0;
  border-left: none;
  border-right: none;
}
.card-image > .fa {
  font-size: 8rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #209cee;
}
.card-content .content {
  font-size: 14px;
  margin: 1rem 1rem;
}
.card-content .content h4 {
  font-size: 16px;
  font-weight: 700;
}
.card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  margin-bottom: 2rem;
}
.intro {
  padding: 5rem 0;
  text-align: center;
}
.sandbox {
  padding: 5rem 0;
}
.tile.notification {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.is-shady {
animation: flyintoright .4s backwards;
background: #fff;
box-shadow: rgba(0, 0, 0, .1) 0 1px 0;
border-radius: 4px;
display: inline-block;
margin: 10px;
position: relative;
transition: all .2s ease-in-out;
}
.is-shady:hover {
box-shadow: 0 10px 16px rgba(0, 0, 0, .13), 0 6px 6px rgba(0, 0, 0, .19);
}
/*adds font awesome stars*/
footer li:before {
  content: '\f1b2';
  font-family: 'FontAwesome';
  float: left;
  margin-left: -1.5em;
  color: #147efb;
}

.content {

    li {
        h4, h5 {
            color: $tcf-red;
        }
    }

    ol {
        list-style: none;
        counter-reset: my-awesome-counter;
        > li {
            counter-increment: my-awesome-counter;
        }
        > li::before {
            display: inline-block;
            content: counter(my-awesome-counter) ". ";
            color: $tcf-red;
            font-size: $size-5;
            font-weight: $weight-semibold;
        }
        &[type="a"] {
            >li::before {
                content: counter(my-awesome-counter, lower-alpha) ". ";
            }
        }

        li {
            ol {
                > li::before {
                    font-size: $size-6;
                    margin-right: 0.5rem;
                    font-weight: $weight-normal;
                }
            }
        }
    }

    li + li {
        margin-top: 1em;
    }
}

.button {
    &.is-link {
        background-color: $tcf-blue;

        &:hover {
            background-color: lighten($tcf-blue, 5%);
        }

        &:active {
            background-color: darken($tcf-blue, 5%);
        }
    }
}

#nav-outer {
    background: $tcf-blue;

    transition: 0.3s all linear;
    a {
        color: $light;
        transition: 0.3s all linear;

        &:hover {
            &.button {
                color: $dark;
            }
        }
    }

    .navbar-burger {
        color: $light;
    }
}

.navbar-item .logo {
    max-height: 5rem;
    padding: 15px;
}

.navbar {
    .tabs {
        ul {
            border-bottom: none;
        }
    }
    &+.container .navbar-menu .navbar-end .tabs ul li a.active {
        background-color: pink;
    }
    .active {
        border-bottom-color: $tcf-red;
    }
}

.navbar-burger {
   transform: scale(1.8);

   &:hover {
       background-color: initial;

       span {
           color: $light;
       }
   }
   
   span {
       left: calc(30% - 8px);
       
       &:nth-child(1) {
           top: calc(72% - 6px);
       }

        &:nth-child(2) {
            top: calc(72% - 1px);
        }

        &:nth-child(3) {
            top: calc(72% + 4px);
        }
   }
}

.hero {
    //padding-top: 3rem;
    background: $tcf-blue;
}

.table {
    margin-left: auto;
    margin-right: auto;
}

.highlight {
    color: $tcf-red;
}

#site {
    background-color: #EFF3F4;
    position: relative;
    z-index: 3;
    overflow: hidden;
    margin-bottom: $footer-height;
}

#footer {
    height: $footer-height;
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 0;

    .container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .footer-content {
        width: 100%;
        display: flex;
        position: relative;
        z-index: 3;
    }

    .logo {
        opacity: 0.3;
        width: 335px;
    }

    .follow {
        font-size: 2rem;
        font-weight: 600;
    }

    .copyright {
        font-size: 1.25rem;
        font-weight: 400;
        margin-bottom: 2rem;
    }

    .crosses {
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        z-index: 1;

        img {
            width: 275px;
        }

        .cross1 {
            transform: translateY(40px) rotate(-3deg);
        }

        .cross3 {
            transform: translateY(40px) rotate(3deg);
        }

        .cross1, .cross3 {
            width: 175px;
        }

        .cross2 {
            padding: 0 20px;
        }
    }
}

@include until($desktop) {
    .tabs.is-right ul {
        justify-content: flex-start;
    }

    #nav-outer {
&.first-section-active {
            .navbar-menu {
                background-color: transparent;
            }
        }
    }
    .navbar-menu {
        background-color: transparent;
    }
}

@include until($tablet) {
    .standard {
        padding: 0 1.5rem;
    }

    .navbar-menu {
        //background-color: $tcf-blue;
        box-shadow: 0 63px 44px 15px rgba(10, 10, 10, 0.5);
        .tabs {
            display: block;
            white-space: normal;
            overflow-x: hidden;

            ul {
                display: block;
            }

            a {
                &:not(.button) {
                    border-bottom: none;
                }
            }
        }
    }

    .navbar .active {
        border-left: 3px solid $tcf-red;
    }

    #footer {
        .footer-content {
            width: auto;
            display: block;
            margin-top: 1.5rem;
        }

        .crosses {
            text-align: center;

            .cross1 {
                transform: translateY(25px) rotate(-3deg);
            }

            .cross3 {
                transform: translateY(25px) rotate(3deg);
            }

            .cross1, .cross3 {
                width: 100px;
            }

            .cross2 {
                width: 150px;
            }
        }
    }
}

// This media query (along with the background-color in the until($tablet)
// when enabled, make the nav always blue on mobile
//@include from($tablet) {
    #nav-outer {
        &:not(.first-section-active) {
            background: white;
            box-shadow: 0 3px 30px 1px rgba(55, 55, 55, 0.2);
            a {
                color: $dark;

                &.button {
                    border-color: $dark;
                }

                &:hover {
                    &.button {
                        background: $dark;
                        color: $light;
                    }
                }
            }

            .navbar-burger {
                color: $dark;
                &:hover {
                    span {
                        color: $dark;
                    }
                }
            }
        }
    }
//}

// Extremely small screens (such as the iPhone 5 and smaller)
@media screen and (max-width: 360px) {
    #footer {
        .follow {
            font-size: 1.5rem;
        }

        .copyright {
            font-size: 0.75rem;
        }

        .logo {
            width: 275px;
        }

        .crosses {
            .cross1, .cross3 {
                width: 75px;
            }

            .cross2 {
                width: 125px;
            }
        }
    }
}
